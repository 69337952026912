import { getPopulateFields } from "@Shared/utils";

import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import {
    CollectionPageSimple,
    Response,
    ResponseCollection,
    ResponseData,
    SinglePageHome,
    SinglePageNotFound,
} from "../types";

export class PagesApi extends Repository {
    getPageHomeData = async (): Promise<SinglePageHome> => {
        const result: Response<ResponseData<SinglePageHome>> = await this.client.request.get(ENDPOINTS.pageHome, {
            populate: `${getPopulateFields(["seo", ["video", "sections.video"], ["video", "sections.videoDevices"]])},sections,sections.cards,sections.cards.image,sections.cards.imageDevices`,
        });

        return result?.data?.attributes ?? null;
    };

    getPageNotFoundData = async () => {
        const result: Response<ResponseData<SinglePageNotFound>> = await this.client.request.get(
            ENDPOINTS.pageNotFound,
            { populate: "button" }
        );

        return result?.data?.attributes ?? null;
    };

    getPageSimpleData = async (slug: string, params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionPageSimple>> = await this.client.request.get(
            ENDPOINTS.pagesSimple,
            {
                filters: {
                    slug,
                },
                populate: getPopulateFields(["seo"]),
                ...params,
            }
        );

        return result?.data?.[0]?.attributes ?? null;
    };

    getAllPagesSimpleData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionPageSimple>> = await this.client.request.get(
            ENDPOINTS.pagesSimple,
            {
                fields: ["slug"],
                ...params,
            }
        );

        return result?.data ?? [];
    };
}
