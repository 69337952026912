import type { IncomingMessage } from "http";

import { toast } from "react-toastify";
import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash-es";
import qs from "qs";
import urlJoin from "url-join";

import { API_URL, DEFAULT_LOCALE, IS_CLIENT, IS_SSR, KEYS } from "@Shared/config";
import { Locale } from "@Shared/types";
import { storage } from "@Shared/utils";

import { Client } from "./client";

export class ApiRequest {
    public readonly req?: IncomingMessage;
    public readonly locale: Locale;

    constructor(protected client: Client) {
        this.req = this.client.context.req;
        this.locale = this.client.context.locale || DEFAULT_LOCALE;
    }

    private fetchData = async (
        apiEndpoint: string,
        queryParams = {},
        fetchParams: Partial<AxiosRequestConfig> = {}
    ) => {
        const fullUrl = urlJoin(
            API_URL,
            apiEndpoint,
            qs.stringify(
                _.defaults(
                    {
                        locale: this.locale,
                    },
                    queryParams
                ),
                { addQueryPrefix: true }
            )
        );

        try {
            const response = await axios({
                url: fullUrl,
                method: "GET",
                headers: this.getHeaders(this.req),
                ...fetchParams,
                timeout: 5000,
            });

            if (response.status === 404) {
                return null;
            }

            return response.data;
        } catch (err: any) {
            let message = null;

            if (err.response) {
                const { error } = err.response.data;

                if (error?.status >= 500) {
                    message = "Что-то пошло не так. Попробуйте позже или перезагрузите страницу (F5).";
                }
            } else {
                message = "Что-то пошло не так. Попробуйте позже или перезагрузите страницу (F5).";
            }

            if (IS_CLIENT && message) {
                toast(message, {
                    position: "bottom-right",
                });
            }

            return false;
        }
    };

    public get = async (apiEndpoint: string, queryParams = {}) => {
        return await this.fetchData(apiEndpoint, queryParams);
    };

    public post = async (apiEndpoint: string, data = {}, queryParams = {}) => {
        return await this.fetchData(apiEndpoint, queryParams, {
            method: "POST",
            data: JSON.stringify(data),
        });
    };

    public put = async (apiEndpoint: string, data = {}, queryParams = {}) => {
        return await this.fetchData(apiEndpoint, queryParams, {
            method: "PUT",
            data: JSON.stringify(data),
        });
    };

    public delete = async (apiEndpoint: string, queryParams = {}) => {
        return await this.fetchData(apiEndpoint, queryParams, {
            method: "DELETE",
        });
    };

    public getLocalizationFields = async (endpoint: string, filters: object) => {
        const data = await this.get(endpoint, {
            locale: "all",
            populate: { localizations: { fields: "id,slug,locale" } },
            filters,
        });

        const localizations: {
            attributes: { id: string; slug: string; locale: string };
        }[] = _.get(data, "data[0].attributes.localizations.data", []);

        return localizations.find(l => l.attributes.locale === this.locale);
    };

    private getLocalizedField = async (
        endpoint: string,
        field: "id" | "slug" | "locale",
        currentValue: any,
        filters: object
    ) => {
        if (DEFAULT_LOCALE === this.locale) {
            return currentValue;
        }

        const localizationFields = await this.getLocalizationFields(endpoint, filters);

        return localizationFields?.attributes?.[field] || currentValue;
    };

    public getLocalizedSlug = async (endpoint: string, currentSlug: string | null): Promise<string> => {
        return this.getLocalizedField(endpoint, "slug", currentSlug, {
            slug: {
                $eq: currentSlug,
            },
        });
    };

    public getLocalizedId = async (endpoint: string, currentId: number): Promise<number> => {
        return this.getLocalizedField(endpoint, "id", currentId, {
            id: {
                $eq: currentId,
            },
        });
    };

    private getHeaders(req?: IncomingMessage) {
        const result: Record<string, any> = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        if (storage.has(KEYS.AUTH)) {
            result["Authorization"] = `Bearer ${storage.get(KEYS.AUTH)}`;
        }

        if (IS_SSR) {
            result["X-Forwarded-Proto"] = "https";
        }

        if (req?.headers.cookie) {
            result["cookie"] = req.headers.cookie;
        }

        return result;
    }
}

export default ApiRequest;
