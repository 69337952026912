import { Locale } from "@Shared/types";

export const IS_DEV = process.env.NODE_ENV !== "production";

export const IS_CLIENT = typeof window !== "undefined";
export const IS_SSR = !IS_CLIENT;

export const API_URL = process.env.NEXT_PUBLIC_API_URL || "/api";
export const UPLOADS_URL = process.env.NEXT_PUBLIC_UPLOADS_URL || "";

export const DEFAULT_LOCALE = Locale[process.env.NEXT_PUBLIC_LOCALE as Locale.all] || "en";

export const KEYS = {
    AUTH: "AUTH_JWT",
};

export const CONTENT_TYPE = {
    json: "application/json",
    formData: "multipart/form-data",
};
