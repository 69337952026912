import { isEmpty } from "lodash-es";

export const delay = (ms: number, deltaMs?: number) =>
    new Promise(resolve => setTimeout(resolve, deltaMs && deltaMs > 0 ? ms + Math.round(Math.random() * deltaMs) : ms));

export const parseOrDefault = (rawValue: string | null | undefined, defaultValue: object | null | undefined = {}) => {
    if (isEmpty(rawValue)) {
        return defaultValue;
    }

    if (typeof rawValue !== "string") {
        return rawValue;
    }

    try {
        return JSON.parse(rawValue);
    } catch (err) {
        console.error(err);

        return defaultValue;
    }
};

type GetTextWithReplaceProps = {
    text: string;
    from?: string;
    to: string;
};

export const getTextWithReplace = ({ text, from = "{value}", to }: GetTextWithReplaceProps) => text.replace(from, to);
