import type { IncomingMessage } from "http";

import { DEFAULT_LOCALE } from "@Shared/config";
import { Locale } from "@Shared/types";

import ApiRequest from "./api-request";
import {
    ArticlesApi,
    CommonApi,
    FormsApi,
    ModalsApi,
    PagesApi,
    ProjectsApi,
    ReviewsApi,
    SocialsApi,
} from "./repositories";

export type ClientCtx = {
    req?: IncomingMessage;
    locale?: Locale;
};

export type ClientOptions = {
    messages: {
        default: string;
    };
};

export class Client {
    public readonly messages;
    public readonly request: ApiRequest;

    // repositories
    public readonly articles: ArticlesApi;
    public readonly common: CommonApi;
    public readonly forms: FormsApi;
    public readonly modals: ModalsApi;
    public readonly pages: PagesApi;
    public readonly projects: ProjectsApi;
    public readonly reviews: ReviewsApi;
    public readonly socials: SocialsApi;

    constructor(
        public context: ClientCtx = {},
        opts?: ClientOptions
    ) {
        this.messages = opts?.messages || {};
        this.request = new ApiRequest(this);

        this.articles = new ArticlesApi(this);
        this.common = new CommonApi(this);
        this.forms = new FormsApi(this);
        this.modals = new ModalsApi(this);
        this.pages = new PagesApi(this);
        this.projects = new ProjectsApi(this);
        this.reviews = new ReviewsApi(this);
        this.socials = new SocialsApi(this);
    }
}

export const client = new Client({ locale: DEFAULT_LOCALE });
