import plugin from "tailwindcss/plugin";

import type { Config } from "tailwindcss";

const config: Config = {
    content: ["./app/**/*.{js,ts,jsx,tsx,mdx}", "./src/**/*.{js,ts,jsx,tsx,mdx}"],
    theme: {
        extend: {
            backgroundImage: {
                "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
                "gradient-radial-half": "radial-gradient(50% 50% at 50% 50%, var(--tw-gradient-stops))",
                "gradient-conic": "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
                "gradient-card-advantage": "radial-gradient(50% 50% at 50% 50%, #2a3fd7 0%, #131313 100%)",
            },
            transitionDuration: {
                DEFAULT: "350ms",
            },
        },
        screens: {
            sm: "640px",
            md: "768px",
            lg: "1025px",
            xl: "1280px",
            "2xl": "1440px",
            "3xl": "1920px",
        },
        fontFamily: {
            primary: "var(--font-montserrat)",
            secondary: "var(--font-inter)",
        },
        fontSize: {
            max: ["112px", "100%"],
            "max-d": ["52px", "110%"],
            h1: ["76px", "100%"],
            "h1-d": ["52px", "110%"],
            "h1-sm-d": ["38px", "100%"],
            h2: ["64px", "100%"],
            "h2-d": ["38px", "100%"],
            "h2-lg": ["64px", "110%"],
            "h2-lg-d": ["38px", "110%"],
            h3: ["64px", "100%"],
            "h3-d": ["32px", "110%"],
            h4: ["28px", "110%"],
            "h4-d": ["22px", "110%"],
            h5: ["24px", "120%"],
            "h5-d": ["20px", "120%"],
            p1: ["20px", "130%"],
            "p1-d": ["16px", "130%"],
            p2: ["16px", "130%"],
            "p2-d": ["14px", "130%"],
            p3: ["14px", "130%"],
            "p3-d": ["12px", "130%"],
        },
        colors: {
            transparent: "transparent",
            current: "currentColor",
            white: {
                DEFAULT: "#ffffff",
                total: "#ffffff",
            },
            black: {
                DEFAULT: "#111111",
                total: "#000000",
            },
            gray: {
                "01": "#272727",
                "02": "#363636",
                "03": "#838383",
                "04": "#b3b3b3",
                "05": "#d1d1d1",
            },
            "bg-blue": "#e9eeff",
            blue: {
                bright: "#5460f6",
                light: "#c6caff",
            },
            green: "#aaff69",
            red: "#ff7878",
        },
    },
    plugins: [
        plugin(({ addVariant }) => {
            addVariant("children", "& > *");
            addVariant("children-hover", "& > *:hover");
            addVariant("child", "& > :first-child");
            addVariant("child-hover", "& > :first-child:hover");
            addVariant("child-last", "& > :last-child");
            addVariant("child-last-hover", "& > :last-child:hover");
        }),
    ],
};
export default config;
